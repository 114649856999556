@import url('https://bossanova.uk/jspreadsheet/v4/jexcel.css');
@import url('https://bossanova.uk/jspreadsheet/v4/jexcel.css');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');


#tool {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  margin-top: 40px;
}

.tool-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px; 
  margin-top: 20px;
}

.left-buttons {
  display: flex;
  gap: 10px;
}

.align-right {
 margin-left: auto;
}


.tool {
  display: flex;
  align-items: center; /* Center vertically */
  margin-top: 100px;
  margin-left: 20px; /* Add a margin to the left */
}


/* CSS stylesheet */
td.jexcel_selectall {
  text-align: center; /* Set the desired width, e.g., 200 pixels */
}

.title {
  background-color: #e0e0e0!important;
  text-transform: uppercase;
}

tr.title > td:nth-child(2) {

  border-bottom: 2px solid black !important;
}

.euro:after {
  content: ' €';
}

.goblack {
  color: black !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-table {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border-collapse: collapse;
  margin-bottom: 20px;

  width: 300px; /* Fixed width for the table */
}

.custom-table td {
  border: 1px solid #cccccc;
  padding: 8px;
  text-align: left;
  width: 180px;
  max-height:30px;
}
.custom-table .euro,
.custom-table .euro::after {
  white-space: nowrap; /* Prevent wrapping to a new line */
}

.custom-table .read-only {
  background-color: #f3f3f3; /* A background color for read-only cells */
}

.summe {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  border-collapse: collapse;
  border: 1px solid #cccccc;
  margin-bottom: 20px;
  width: 500px; /* Fixed width for the table */
}

.summe td {
  border: 1px solid #cccccc;
  padding: 8px;
  text-align: left;
  font-weight: 600;
  width: 180px;
  max-height:30px;
}

.summe tr > td:first-child {
  background-color: #f3f3f3;
}
.summe tr > td:nth-child(2) {
  text-align: right;
}




#editable-value {
  border: none;
  outline: none;
  background: transparent;
  max-width: 60px;
  font-weight: 600;
  text-align: right;
}

#tool tr td:nth-child(2) {
  word-wrap: break-word;
  white-space: normal;
}

tr.title td:nth-child(7),
tr.title td:nth-child(1),
tr.title td:nth-child(2) { 
  font-weight: bold;        
}

.toolbutton {
  border: 1px solid #cccccc;
  font-family: 'Poppins', sans-serif;
  background-color: #f3f3f3;
  padding: 5px 15px;
  border-radius: 5px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

.toolbutton:hover {
  background: linear-gradient(to bottom, #f5f5f5, #d9d9d9);
  transform: translateY(-1px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
}

.toolbutton:active {
  transform: translateY(1px);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
}


@media screen and (max-width: 1183px) {
  .tool-container {
    justify-content: flex-start; /* Aligns children (flex items) at the start of the container */
  }

  .tool {
    margin-left: 10px; /* Reset the left margin */
  }

  /* Optionally, you can also set the width of the tool to a specific value or percentage */
  .tool {
    width: 100%; /* Adjust as needed */
  }

  /* If you need to adjust the width of a specific table when the screen size is less than 1183px */
  .custom-table, .summe {
    width: auto; /* Or any other value that fits better */
    max-width: 100%; /* Ensures the table does not overflow the screen width */
  }
}