.App {
  text-align: center;
}

#parent-container {
  display: flex;        /* Use flexbox */
  justify-content: center; /* Center horizontally */
  align-items: center;     /* Center vertically (if you want vertical centering as well) */
  min-height: 100vh;       /* Full height of the viewport if you want vertical centering */
  flex-direction: column;  /* Stack children vertically */
}
